import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const GDPRPrivacyNotice = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>GDPR Privacy Notice</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>
                Data Protection Officer (DPO)
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Travel Lingual has appointed a Data Protection Officer
                responsible for ensuring our compliance with data protection
                laws. You can reach our DPO at james@travel-lingual.com.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Data Collection and Use</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We collect and process personal data in accordance with the
                General Data Protection Regulation (GDPR) for legitimate
                business purposes, such as providing services, improving our
                website, and communicating with users. We will obtain your
                consent when required by law.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Your Rights</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Under the GDPR, you have the following rights:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to access your personal data
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to rectify inaccurate data
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to erasure (in certain circumstances)
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to restrict processing
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to data portability
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right to object to processing
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Right not to be subject to automated decision-making
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>
                To exercise your rights or inquire about your personal data,
                please contact our Data Protection Officer.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Data Security</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We take data security seriously and implement appropriate
                measures to protect your personal information from unauthorized
                access, disclosure, alteration, and destruction.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Data Retention</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We retain personal data only for as long as necessary for the
                purposes for which it was collected and in accordance with legal
                requirements.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Complaints</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                If you have concerns about our data processing practices, you
                have the right to lodge a complaint with the Information
                Commissioner's Office (ICO) in the UK or the relevant data
                protection authority in your jurisdiction.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                This policy is subject to periodic review and may be updated to
                reflect changes in data protection laws or our practices. Please
                check this policy regularly for updates.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default GDPRPrivacyNotice;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"GDPR Privacy Notice | Travel-Lingual"}
      description={
        "Affiliate Disclaimer: Learn about our affiliate relationships and how they support our mission to provide you with valuable travel information and resources."
      }
      pathname={location.pathname}
    />
  );
};
